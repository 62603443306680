<template>
  <div>
    <el-dialog
      title="批量修改"
      :visible.sync="visible"
      width="500px"
      :close-on-click-modal="false"
    >

      <el-form ref="BatchModifyForm" :model="BatchModifyForm" label-width="120px">
        <el-form-item ref="returnRate" label="退货率" prop="returnRate">
          <el-input-number v-model="BatchModifyForm.returnRate" controls-position="right" :min="0" :max="100" :precision="2" style="width:80%" placeholder="请输入0-100的数字,精确到两位小数" />
          %
        </el-form-item>
        <el-form-item ref="safeStockDays" label="安全库存天数" prop="safeStockDays">
          <div>
            <el-input-number v-model="BatchModifyForm.safeStockDays" :precision="0" controls-position="right" :min="0" :max="999999" style="width:80%" placeholder="请输入0~999999整数" />
            天
          </div>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('input',false)">取 消</el-button>
        <el-button type="primary" :disabled="batchModifyDialogVisible" @click="batchCompletionDialogSure">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
// import { shortageCompletion } from '@/api/scm-api'
import { BatchModifySsr } from '@/api/scm-api'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { trackEvent, handleBreadTitle } from '@/utils/monitor'

export default {
  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    batchList: {
      type: Array,
      default: () => {
        []
      }
    }
  },
  data() {
    return {
      BatchModifyForm: {
        safeStockDays: undefined,
        returnRate: undefined
      },
      batchModifyDialogVisible: false
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    BatchModifyFormParams() {
      const { safeStockDays, returnRate } = this.BatchModifyForm
      return Object.assign({}, { safeStockDays, returnRate }, { idList: this.batchList })
    }
  },
  watch: {
    'visible'(val) {
      if (val) {
        // this._queryWarehouseList()
      } else {
        Object.assign(this.BatchModifyForm, this.$options.data().BatchModifyForm)
        this.$nextTick(() => {
          this.$refs.BatchModifyForm.resetFields()
        })
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    batchCompletionDialogSure() {
      this.$refs.BatchModifyForm.validate(async valid => {
        if (valid) {
          try {
            this.batchModifyDialogVisible = true
            const { returnRate } = this.BatchModifyForm
            if (this.BatchModifyFormParams.safeStockDays >= 0 || this.BatchModifyFormParams.returnRate >= 0) {
              const msg = `你选中了${this.batchList.length}条数据进行修改${returnRate > 60 ? ',退货率大于60%' : ''}，该操作无法撤销，请确认！`
              this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(async() => {
                const { code, msg } = await BatchModifySsr(this.BatchModifyFormParams)
                if (code === 0) {
                  this.$notify({
                    message: msg,
                    type: 'success'
                  })
                  trackEvent('批量修改', handleBreadTitle(this.$route) + `修改${this.BatchModifyFormParams?.idList?.length}条数据`,)
                  this.$emit('input', false)
                  this.$emit('refresh')
                }
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消修改'
                })
              })
            } else {
              this.$message({
                message: '请填写【退货率】或【安全库存天数】',
                type: 'error'
              })
              return
            }
          } finally {
            this.batchModifyDialogVisible = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

/deep/.el-select {
            width: 100%  !important;
        }
</style>
