<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="平台/站点/店铺" :class="$i18n.locale">
              <el-cascader
                v-model="form.platformSiteShop"
                clearable
                collapse-tags
                filterable
                :options="alldatas"
                :props=" { value: 'value', label: 'dropDownName', children: 'dropDownList', multiple:true}"
                style="width: 100%;"
                @change="platformSiteShopChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="品牌">
              <el-select
                v-model="form.brandIdList"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in brandOptions"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="类别" :class="$i18n.locale">
              <el-cascader
                v-model="form.categoryIdList"
                clearable
                collapse-tags
                filterable
                :options="basecategorydatas"
                separator=">"
                :filter-method="dataFilter"
                class="style_w100"
                :props="{
                  value: 'id',
                  label: 'categoryName',
                  children: 'sonCategory',
                  multiple: true,
                }"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="季节" :class="$i18n.locale">
              <el-select
                v-model="form.seasonIdList"
                class="style_w100"
                clearable
                filterable
                multiple
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in _getAllCommodityDict('SEASON')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="BU">
              <Select
                v-model="form.buIdList"
                class="style_w100"
                api-key="buList"
                clearable
                multiple
                collapse-tags
                :configuration=" {
                  key: 'id',
                  label: 'buName',
                  value: 'id'
                }"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="定位" :class="$i18n.locale">

              <Select
                v-model="form.positionIdList"
                style="width:100%;"
                api-key="position"
                clearable
                filterable
                collapse-tags
                multiple
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Style">
              <el-select
                v-model="form.styleIdList"
                value-key="id"
                clearable

                filterable
                :placeholder="$t('page.selectPlaceholder')"
                style="width: 100%;"
                @change="styleSelectChange"
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="Color" :class="$i18n.locale">
              <el-select
                v-model="form.colorIdList"
                clearable
                filterable
                multiple
                collapse-tags
                id:placeholder="$t('page.selectPlaceholder')"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Size" :class="$i18n.locale">
              <el-select
                v-model="form.sizeIdList"
                clearable
                filterable
                multiple
                collapse-tags
                id:placeholder="$t('page.selectPlaceholder')"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.size"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Sku">
              <el-input v-model="form.sku" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人" :class="$i18n.locale">
              <el-select v-model="form.modifyIdList" clearable filterable style="width:100%;" multiple collapse-tags>
                <el-option v-for="item in useroptions" :key="item.id" :label="item.username" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作时间" :class="$i18n.locale">
              <el-date-picker
                v-model="form.value1"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width:100%;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="退货率(%)" :class="$i18n.locale">
              <InputRange
                v-model="form.returnRate"
                :precision="2"
                :user-input-to-max="100"
                :user-input-form-min="0"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="安全库存天数(天)" :class="$i18n.locale">
              <InputRange
                v-model="form.safeStockDay"
                :precision="0"
                :user-input-to-max="999999"
                :user-input-form-min="0"
              />
            </el-form-item>
          </el-col>
        </el-row>

      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" :loading="Loading" @click="queryClickSearch">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-row :span="24" class="mb-3">
      <el-col :span="12">
        <el-button v-permission="'add'" type="primary" @click="handleAdd">新增</el-button>
        <el-button v-permission="'edit'" type="primary" @click="handleModify">修改</el-button>

        <!-- 批量导入、批量修改 弹窗 -->
        <el-button v-permission="'batchImport'" class="mr-3" type="primary" @click="uploadDialogVisible=true">批量导入</el-button>
        <el-button v-permission="'batchEdit'" class="mr-3" type="primary" @click="handleChange">批量修改</el-button>

        <el-button v-permission="'deleteA'" class="mr-3" type="danger" @click="handleDelete()">删除</el-button>

      </el-col>
      <el-col :span="12" style="text-align: right;">
        <el-button v-permission="'exportA'" class="mr-3" type="primary" @click="exportDetail(rtrssdExport,true)">导出</el-button>
        <el-button
          class="mr-3"
          type="primary"
          @click="exportDetail(exportRecycle)"
        >回收站数据导出</el-button>

      </el-col>
    </el-row>

    <el-table
      ref="multipleTable"
      v-loading="Loading"
      class="mb-3 mt-3"
      :data="tableDatas"
      max-height="550px"
      :header-cell-style="{background:'#fafafa'}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="left"
        prop="platformName"
        label="平台"
        min-width="100"
      />

      <el-table-column
        prop="siteName"
        label="站点"
        align="center"
        min-width="80"
      />
      <el-table-column
        prop="shopName"
        label="店铺"
        align="center"
        min-width="160"
      />
      <el-table-column
        prop="brandName"
        label="品牌"
        align="center"
        min-width="100"
      />
      <el-table-column label="类别" width="140">
        <template slot-scope="scope">
          <span>{{ Array.isArray(scope.row.categoryNameList)&&scope.row.categoryNameList[0] && scope.row.categoryNameList.join('>') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="seasonName"
        label="季节"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="buName"
        label="BU"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="positionName"
        label="定位"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="styleName"
        label="Style"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="colorName"
        label="Color"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="sizeName"
        label="Size"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="sku"
        label="Sku"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="returnRateStr"
        label="退货率"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="safeStockDays"
        label="安全库存天数（天）"
        align="center"
        min-width="150"
      />
      <el-table-column
        prop="modifyByName"
        label="操作人"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="modifyTime"
        label="操作时间"
        align="center"
      />

    </el-table>

    <Paging :pager="pager" end @pagination="pagerUpdate" />
    <BatchModify v-model="batchModifyDialogVisible" :batch-list="batchList" @refresh="queryClick" />
    <Upload v-model="uploadDialogVisible" @refresh="queryClick" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Paging from '@/components/Pagination'
import BatchModify from './components/BatchModify'
import Upload from './components/Upload'
import { queryStyleList, querySizeList, queryColorList, usersList, getplatformSiteShop, rtrssdApi, rtrssdExport, DeleteSsr, exportRecycle } from '@/api/scm-api'
import { queryBrandList } from '@/api/listSelection'
import { handleDownload, handleMonitorParams } from '@/utils'
import { getInfo } from '@/api/user'
import { trackSiteSearch, handleBreadTitle, trackExportEvent } from '@/utils/monitor'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Select from '@/components/Selection'
import { getBasecategory } from '@/api/configuration'
import { omit } from 'lodash'
import InputRange from '@/components/InputRange'

export default {
  components: { Paging, BatchModify, Upload, Select, InputRange },
  mixins: [commodityInfoDict],

  data() {
    return {
      rtrssdExport,
      exportRecycle,
      basecategorydatas: [],
      userName: '',
      form: {
        value1: [],
        style: '',
        vendorId: ''
      },
      pager: {
        current: 1,
        size: 10,
        total: 0
      },
      styleOptions: [],
      brandOptions: [],
      colorOptions: [],
      sizeOptions: [],
      useroptions: [],
      tableDatas: [],
      alldatas: [],
      Loading: false,
      uploadDialogVisible: false,
      batchModifyDialogVisible: false,
      batchList: [], // 批量修改
      selectedId: '' // 修改
    }
  },
  computed: {
    SsdParams() {
      const { value1 = [], platformSiteShop = [], categoryIdList = [], styleIdList, returnRate, safeStockDay } = this.form
      const [returnRateStart, returnRateEnd] = returnRate || []
      const [safeStockDayStart, safeStockDayEnd] = safeStockDay || []
      const [modifyTimeStart, modifyTimeEnd] = value1 || []
      const platformSiteShopVOList = platformSiteShop?.filter(item => Array.isArray(item) && item.length >= 3)
        .map(([platform, site, shop]) => {
          const [platformId, platformName] = platform.split('&&')
          const [siteId, siteName] = site.split('&&')
          const [shopId, shopName] = shop.split('&&')
          return { platformId: parseInt(platformId), platformName, siteId: parseInt(siteId), siteName, shopId: parseInt(shopId), shopName }
        })

      const lastCategoryIdList = categoryIdList.flatMap(v => v.slice(-1)).filter(Boolean)

      return {
        ...this.pager,
        ...omit(this.form, ['value1', 'platformSiteShop', 'categoryIdList', 'returnRate', 'safeStockDay']),
        modifyTimeStart,
        modifyTimeEnd,
        platformSiteShopVOList,
        categoryIdList: lastCategoryIdList,
        styleIdList: styleIdList ? [styleIdList] : [],
        returnRateStart, returnRateEnd,
        safeStockDayStart, safeStockDayEnd
      }
    }

  },
  created() {

  },
  async mounted() {
    this._getBasecategory()
    this._queryStyleList()
    this._queryBrandList()
    this._queryColorList()
    this._querySizeList()
    this._usersList()
    this._listPlatformSiteShop()
    // 保存当前用户的平台站点店铺
    const { datas } = await getInfo()
    this.userName = datas.username
    const selectedDatas = localStorage.getItem('rtr-ssd-configuration')
    if (selectedDatas) {
      const newUser = selectedDatas.split('+')[0]
      const OldplatformSiteShop = selectedDatas.split('+')[1]
      if (newUser === this.userName) {
        var arr = OldplatformSiteShop.split(',')
        var result = []
        for (var i = 0; i < arr.length; i += 3) {
          result.push(arr.slice(i, i + 3))
        }
        this.form.platformSiteShop = result
      }
    }
    this.queryClick()
  },
  methods: {

    async _getBasecategory() {
      const { datas } = await getBasecategory()
      this.basecategorydatas = datas
    },
    dataFilter(node, val) {
      if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
        return true
      }
    },
    queryClickSearch() {
      this.pager.current = 1
      this.queryClick()
      trackSiteSearch(
        JSON.stringify(handleMonitorParams(this.SsdParams)),
        handleBreadTitle(this.$route)
      )
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.queryClick()
    },
    // 查询
    async queryClick() {
      try {
        this.Loading = true
        const { code, datas: { records, pager }} = await rtrssdApi(this.SsdParams, this.pager)
        if (code === 0) {
          this.pager = pager
          this.tableDatas = records
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.Loading = false
      }
    },
    // 导出
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    async exportDetail(api, notCycleFlag = false, fileName = 'RTR&SSD配置文件.xlsx') {
      try {
        this.exportLoading = true
        const params = {}
        const idList = []
        if (notCycleFlag && Array.isArray(this.multipleSelection) && this.multipleSelection.length) {
          this.multipleSelection.map(item => idList.push(item.id))
          Object.assign(params, { idList: idList })
        } else {
          Object.assign(params, this.SsdParams)
        }
        const data = await api(params)
        notCycleFlag ? handleDownload(data, fileName) : this.$notify({
          message: '操作成功，请前往文件导出界面下载',
          type: 'success'
        })
        this.exportLoading = false
        trackExportEvent(handleBreadTitle(this.$route))
      } finally {
        this.exportLoading = false
      }
    },
    // 批量导入

    // 批量修改
    handleChange() {
      if (!Array.isArray(this.multipleSelection) || !this.multipleSelection.length) {
        this.$message({
          message: '请至少选择一条数据修改',
          type: 'warning'
        })
        return false
      } else {
      // 遍历与修改
        this.batchList = this.multipleSelection.map(item => item.id)
        this.batchModifyDialogVisible = true
      }
    },
    // 新增
    handleAdd() {
      this.$router.push({
        path: 'added',
        append: true
      })
    },
    // 修改
    handleModify() {
      if (!Array.isArray(this.multipleSelection) || this.multipleSelection.length !== 1) {
        return this.$message.warning('仅可选中单笔数据进行操作')
      }
      this.selectedId = this.multipleSelection[0]
      this.$router.push({
        path: 'added',
        append: true,
        query: this.selectedId
      })
    },
    // 删除
    handleDelete() {
      if (!Array.isArray(this.multipleSelection) || !this.multipleSelection.length) {
        this.$message({
          message: '请至少选择一条数据删除',
          type: 'warning'
        })
        return false
      } else {
        this.$confirm(`你选中了${this.multipleSelection.length}条数据进行删除，该操作无法撤销，请确认！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const idList = this.multipleSelection.map(item => item.id)
          await DeleteSsr(idList)
          this.queryClick(1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      }
    },
    // 保存平台站点店铺筛选信息
    platformSiteShopChange(value) {
      const selectedDatas = `${this.userName}+${value}`
      localStorage.setItem('rtr-ssd-configuration', selectedDatas.toString())
    },
    // 下拉信息获取
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    async _queryBrandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    styleSelectChange(value) {
      const data = this.styleOptions.find(item => item.id === value) || []
      this._queryColorList(data.id)
      this._querySizeList(data.id)
    },
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    async _usersList() {
      const { datas } = await usersList()
      this.useroptions = datas
    },
    async _listPlatformSiteShop() {
      const { datas } = await getplatformSiteShop()
      this.getDataTree(datas)
      this.alldatas = datas
    },
    getDataTree(data) {
      if (!Array.isArray(data)) return
      for (let i = 0; i < data.length; i++) {
        data[i].value = `${data[i].dropDownId}&&${data[i].dropDownName}`
        this.getDataTree(data[i].dropDownList)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.style_w100 {
  width: 100%;
}
</style>
