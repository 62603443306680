<template>
  <div>
    <el-dialog
      title="批量导入"
      :visible.sync="visible"
      width="80%"
      :close-on-click-modal="false"
      @close="closed"
    >
      <el-steps :active="active" align-center finish-status="success">
        <el-step title="上传文件" />
        <el-step title="数据预览" />
        <el-step title="导入数据" />
      </el-steps>

      <main class="upload-order-content">
        <section v-show="active === 0" class="import-orders">
          <div class="download-template">
            <h3 class="my-2">填写导入数据信息</h3>
            <small>请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除</small>
            <el-button
              class="mt-2"
              type="text"
              :loading="downloadLoding"
              @click="handleTemplate"
            >下载模板</el-button>
          </div>
          <el-divider />
          <div>
            <h3 class="my-2">上传填好的信息表</h3>
            <small>文件后缀名必须为xls
              或xlsx（即Excel格式），文件大小不得大于10M，最多不超过100000条数据</small>
            <ImportFile
              ref="uploadRef"
              :limit="1"
              class="mt-8px"
              :auto-upload="false"
              :upload-file="uploadPlanFile"
              accept=".xlsx, .xls"
              :types="['xls', 'xlsx']"
              :show-file-list="true"
              :size="50"
              button-type="text"
              import-name="上传文件"
              @getFile="({file:val})=>file=val"
              @removeFile="handleFileRemove"
            />
          </div>
          <el-divider />

          <div class="download-template1">
            <h3 class="my-2">特别提示</h3>
            <small>导入过程中如发现个别数据校验不通过，则全量回滚修正后再重新操作导入</small>
          </div>
        </section>
        <section v-show="active === 1" class="import-orders">
          <div class="upload-error">
            <div class="upload-error--item">
              <span class="my-2">正常数量条数：</span>
              <el-link type="success" class="success-account" :underline="false">
                {{ tableInfo.errorResult.successAccount || 0 }} 条
              </el-link>
            </div>
            <div class="upload-error--item">
              <span class="my-2">异常数量条数：</span>
              <el-link type="danger" class="error-account mr-2" :underline="false">
                {{ tableInfo.errorResult.errorAccount || 0 }} 条
              </el-link>
              <ElButton v-show="tableInfo.errorResult.errorAccount" type="text" :loading="errorLoading" @click="handleExport">下载异常数据详情</ElButton>
            </div>
          </div>
          <el-divider />

          <vxe-table
            ref="multipleTable"
            max-height="500px"
            align="center"
            :data="tableInfo.defaultList"
            :cell-style="cellStyle"
            :row-config="{ height: 80 }"
          >
            <vxe-table-column width="50" type="seq" title="序号" />
            <vxe-table-column
              field="message"
              title="错误信息"
              min-width="140"
            />

            <vxe-table-column field="platformName" title="平台" width="90" />
            <vxe-table-column field="siteName" title="站点" width="90" />
            <vxe-table-column field="shopName" title="店铺" width="90" />
            <vxe-table-column field="styleName" title="Style" width="90" />
            <vxe-table-column field="colorName" title="Color" width="100" />
            <vxe-table-column field="sizeName" title="Size" width="100" />
            <vxe-table-column field="sku" title="Sku" width="100" />
            <vxe-table-column field="returnRate" title="退货率(%)" width="120" />
            <vxe-table-column field="safeStockDays" title="安全库存天数（天）" width="120" />

          </vxe-table>
          <Paging :page-sizes="[10, 20, 40, 100]" :pager="pager" end @pagination="pagerUpdate" />
        </section>
        <section v-show="active === 2" class="data-review">
          <el-progress :text-inside="true" :stroke-width="26" :percentage="percentage" />
          <p
            v-if="percentage"
            class="mt-16px"
            style="text-align: center"
          >正在导入数据，请勿关闭或刷新页面</p>
        </section>
        <section v-show="active === 3" style="text-align: center">
          <el-result icon="success" title="批量导入完成" :sub-title="`您已成功导入${tableInfo.tableList.length}条数据`" />
        </section>
      </main>
      <template #footer>
        <span class="dialog-footer">
          <el-button v-show="active === 0" @click="closed">取消</el-button>
          <el-button v-show="active === 1" @click="handleToReview('previous')">上一步</el-button>
          <el-button
            v-show="active === 0"
            type="primary"
            :loading="loading"
            @click="handleToReview('next')"
          >下一步</el-button>
          <el-button
            v-show="active === 1"
            type="primary"
            :loading="loading"
            @click="handleToReview('submit')"
          >提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { trackEvent, handleBreadTitle, trackDialogEvent } from '@/utils/monitor'
import ImportFile from '@/components/ImportFile'
import { ImportViewSsr, ImportSsr } from '@/api/scm-api'
import Paging from '@/components/Pagination'
export default {
  components: { ImportFile, Paging },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pager: { size: 20, current: 1, total: 0 },
      multipleTable: [],
      file: '',
      fileList: [],
      active: 0,
      downloadLoding: false,
      loading: false,
      errorLoading: false,
      percentage: 0,
      importError: false,
      tableInfo: {
        defaultList: [],
        tableList: [],
        errorResult: {
          successAccount: 0,
          errorAccount: 0
        }
      },
      tip: '退货率大于60%，是否继续提交？当前平台/站点/店铺Style/Color/SIze存在启用的退货率及安全库存天数，确认提交将视作修改当前数据，请确认。'
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.file = ''
        this.$refs.uploadRef.$refs.uploadForm.clearFiles()
      } else {
        // 记录次数
        trackDialogEvent(handleBreadTitle(this.$route) + '批量导入')
      }
    },
    'active'(val) {
      if (val !== 2) {
        this.importError = false
      }
    }
  },
  methods: {
    async handleTemplate() {
      try {
        this.downloadLoding = true
        const url = 'https://aliyun-oa-query-results-1509030252432744-oss-cn-hongkong.oss-cn-hongkong.aliyuncs.com/template/scm/RTR%26%26SSD%E9%85%8D%E7%BD%AE%E6%96%B0%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls'
        window.open(url, '_blank')
      } finally {
        this.downloadLoding = false
      }
    },
    pagerUpdate(val) {
      Object.assign(this.pager, val)
      const { current, size } = this.pager
      this.tableInfo.defaultList = this.tableInfo.tableList.slice((current - 1) * size, current * size)
    },
    handleFileRemove() {
      this.$refs.uploadRef.$refs.uploadForm.clearFiles()
      // this.file = val
    },
    async uploadPlanFile() {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('file', this.file)
        const { code, datas } = await ImportViewSsr(formData)
        if (code === 0) {
          this.pager.current = 1
          this.tableInfo.tableList = datas
          const { current, size } = this.pager
          this.pager.total = datas.length
          this.tableInfo.defaultList = datas.slice((current - 1) * size, current * size)
          const successAccount = this.tableInfo.tableList.filter((item) => (!item.message)).length
          Object.assign(this.tableInfo.errorResult, {
            successAccount,
            errorAccount: this.tableInfo.tableList.length - successAccount
          })
          this.file = ''
          this.fileList = []
          this.active++
        }
      } finally {
        this.loading = false
        this.$refs.upload.$refs.uploadForm.clearFiles()
      }
    },
    handleToReview(type) {
      this.currentStatus(type)
    },
    closed() {
      this.file = ''
      this.active = 0
      this.$emit('input', false)
    },
    currentStatus(type) {
      if (type === 'next') {
        if (this.active === 0) {
          if (!this.file) return this.$message.warning('请上传文件')
          this.$refs.uploadRef.$refs.uploadForm.submit()
        }
      }
      if (type === 'previous') {
        this.active--
      }
      if (type === 'submit') {
        if (this.tableInfo.tableList.some((item) => (item.message && this.tip.indexOf(item.message) === -1))) { return this.$message.warning('当前预览数据存在错误，无法提交') }
        this.handleImport()
        this.active = 2
      }
      if (type === 'success') {
        this.active = 3
        this.$emit('refresh')
      }
    },
    async handleImport() {
      try {
        this.loading = true
        this.active = 3
        const { tableList } = this.tableInfo
        const { code } = await ImportSsr(tableList)
        if (code === 0) {
          // 提示成功页面
          this.percentage = 100
          this.currentStatus('success')
          trackEvent('批量导入', handleBreadTitle(this.$route) + `导入${this.tableInfo.tableList.length}条数据`,)
        }
      } catch (e) {
        this.percentage = 0
        this.importError = true
      } finally {
        this.loading = false
      }
    },
    // 下载错误信息表格
    async handleExport() {
      try {
        this.errorLoading = true

        import('@/vendor/Export2Excel').then((excel) => {
          const header = ['序号', '错误信息', '平台', '站点', '店铺', 'Style', 'Color', 'Size', 'Sku', '退货率(%)', '安全库存天数（天）']
          const filterVal = ['no', 'message', 'platformName', 'siteName', 'shopName', 'styleName', 'colorName', 'sizeName', 'sku', 'returnRate', 'safeStockDays']
          const data = this.formatJson(filterVal, this.tableInfo.tableList)
          excel.export_json_to_excel({
            header,
            data,
            filename: `异常数据列表`
          })
          setTimeout(() => {
            this.errorLoading = false
          }, 1000)
        })
      } catch (err) {
        this.errorLoading = false
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, i) => filterVal.map(j => {
        if (j === 'no') {
          return i + 1
        } else {
          return v[j]
        }
      }))
    },
    cellStyle({ row, column }) {
      if (row.message) {
        if (this.tip.indexOf(row.message) !== -1) {
          return {
            color: 'orange'
          }
        } else {
          return {
            color: 'red'
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.upload-dialog /deep/ {
    .el-step__title.is-success, .el-step__head.is-success , .el-step__description.is-success,
    .el-step__title.is-process, .el-step__head.is-process , .el-step__description.is-process{
    color: #1890ff;
    border-color: #1890ff;
  }
}
.upload-order-content {
  width: 90%;
  padding: 50px 0px;
  margin: 0 auto;

  .download-template {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .download-template1 {
    display: flex;
    padding: 30px 10px;
    flex-direction: column;
    align-items: flex-start;
    background: rgb(255, 245, 230);
  }

  .upload-error--item {
    display: flex;
    align-items: center;
  }

  .upload-order {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .el-upload--text {
      order: 2;
    }

    .el-upload__tip {
      order: 1;
    }

    .el-upload-list.el-upload-list--text {
      order: 3;
    }
  }
}
</style>

